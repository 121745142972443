import { useFormik } from 'formik';
import { useDispatch, useSelector} from 'react-redux';

import { errorHelper } from '../../utils/tools';
import { updateProfile, updateProfileAvatar } from '../../store/actions/profile'

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import { Container, Row, Col } from 'react-bootstrap';
import { useState } from 'react';


import AccountAvatar from './accountAvatar';
import LayoutCard from '../../hoc/layoutCard';
import { MAX_IMAGE_SIZE } from '../../utils/costants';
import { EventNote } from '@mui/icons-material';
const UserCard = () => {
    const { fullname, username } = useSelector(state=>state.profile.data)

    // const [currentFirstname, setFirstname] = useState(firstname);
    // const [currentLastname, setLastname] = useState(lastname);
    let locked = false;

    const [selectedFile, setSelectedFile] = useState(null);



    const dispatch = useDispatch();


    const updateAvatar = (file) => {
      dispatch(updateProfileAvatar(file))
      

    }
  
    


    return(
        <LayoutCard 
          title={'IL TUO UTENTE'}
        >
          <Container className="grey-card padding-20">
            <Row>
              <Col md={4} className="user-card-content">
                <AccountAvatar size={150} />

              </Col>
             
              <Col md={5} className="user-card-label user-card-content">
                  <label>Utente: </label><span><b> {fullname}</b></span><br></br>
                  
                  <a href={process.env.REACT_APP_EDIT_PROFILE_URL + username} target="_blank">
                    <Button className='ui-button'>Gestione profilo</Button>

                  </a>
                 
              </Col>
             
            </Row>
          </Container>
        </LayoutCard>
        
    )

}


export default  UserCard