import FacebookRounded from '@mui/icons-material/FacebookRounded'
import YouTube from '@mui/icons-material/YouTube'
import Instagram from '@mui/icons-material/Instagram'
import Twitter from '@mui/icons-material/Twitter'
import SimpleSearchIcon from '@mui/icons-material/Search';
import {Button} from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FACEBOOK_URL,  INSTAGRAM_URL, ALERT_FOLDER, TWITTER_URL, YOUTUBE_URL } from '../../../utils/costants'
import { useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { getMenu } from '../../../store/actions/menuItems'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import ProfileDropdown from './profileDropdown';
const HeaderMain = () => {

    const menu = useSelector(state=>state.menuItems.menu)
    const isAuth = useSelector(state=>state.profile.auth)

    
    const dispatch = useDispatch();

    useEffect(()=>{
        if(isAuth && menu.length == 0) {
            dispatch(getMenu())
        }

       
      }, [isAuth])
      
    const handleResize = () => {
        //NOTE: if scroll il disabled and the window is resized the scroll remain disabled. I must force to visible
        if(window.innerWidth > 992) {
            setNavExpanded(false)
        }
        
    }

      //document.body.style.overflow = "hidden"
    window.addEventListener('resize', handleResize)

    const setNavExpanded = (expanded) => {
        setExpanded(expanded);
        document.body.style.overflow = expanded ? "hidden" : "visible"

    }
    const [expanded, setExpanded] = useState(false)

    return(
        <>
        <Navbar collapseOnSelect expand="lg" onToggle={setNavExpanded} expanded={expanded}>
      <Container>
        <Navbar.Brand>
            <Nav.Link eventKey={"home"} as={Link} to="/">
                <img src="/logo-cisl.png" height={60} className='logo-big d-none d-lg-block' />
                <img src="/logo-cisl.png" height={60} className='d-block d-lg-none'/>
            </Nav.Link>
        </Navbar.Brand>
        <Nav.Link eventKey={"search"} as={Link} to="/search" className='d-block d-lg-none'>

            <SimpleSearchIcon />
        </Nav.Link>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="ms-auto">
            { 
                menu && menu.length > 0 ? 
                    menu.map((item,index)=>{
                        if(item.link.indexOf('http') == -1) {
                            return (
                                <Nav.Link eventKey={index} key={index} as={Link} to={item.link}>{item.title} </Nav.Link>    
                            )
                        }
                        else {
                            return (
                                <Nav.Link eventKey={index} key={index} href={item.link} target="_blank">{item.title} </Nav.Link>
                            )
                        }
                        
                        
                    })
                :
                    ""
            }
            <ProfileDropdown onlyMobile={true} className='no-border' />
            <div className='social-icons'>
                <a href={FACEBOOK_URL} target="_blank">
                    <FacebookRounded />
                </a>
                <a href={YOUTUBE_URL} target="_blank">
                    <YouTube />
                </a>
                <a href={INSTAGRAM_URL} target="_blank">
                    <Instagram />
                </a>
                <a href={TWITTER_URL} target="_blank">
                    <Twitter />
                </a>
            </div>
            <Button as={Link} to={'/logout'} variant="outline-success" className='d-block d-sm-none nav-button-exit'>ESCI</Button>

          </Nav>
          


          
        </Navbar.Collapse>
        
      </Container>
    </Navbar>
       
            
        </>
    )

}


export default HeaderMain