import { Container, Row, Col } from 'react-bootstrap';
import ArrowCircleUpIcon from '@mui/icons-material/ArrowCircleUp';
import { IconButton } from '@mui/material';
import { VERSION } from '../../utils/costants';
const Footer = () => {
 
    const goTop = () => {
        document.body.scrollTop = 0; // For Safari
        document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
    }
    return(
        <>
           <nav className='footer-nav'>
                <Container>
                    <Row>
                        <Col xs={10}>
                        Intranet CISL - Versione {VERSION}

                        </Col>
                        <Col className='footer-up-icon'>
                            <IconButton aria-label="move" onClick={goTop} >
                                    <ArrowCircleUpIcon sx={{ fontSize: 40, color: "white" }} />
                            </IconButton>
                        
                        </Col>
                    </Row>
                    
                </Container>
               
                
                
              
            </nav>
        </>
    )
}

export default Footer;