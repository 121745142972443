import { useSelector } from 'react-redux'
import LayoutCard from '../../hoc/layoutCard';
import { useDispatch} from 'react-redux';
import { getTrash } from '../../store/actions/specialFolder';
import { Loader, formatDate } from '../../utils/tools';
import { Modal, Container, Row, Col, Button } from 'react-bootstrap';
import { getAllAccessibleFolders } from '../../store/actions/folders';
import { moveFolder} from '../../store/actions/folders'
import ElementCard from '../elements/elementCard';
import { movePost } from '../../store/actions/posts';
import {Tooltip}  from '@mui/material';
import { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { useRef } from 'react';
import { itIT } from '@mui/x-data-grid';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import { IconButton } from '@mui/material';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import FolderIcon from '@mui/icons-material/Folder';
import { DataGridPro } from '@mui/x-data-grid-pro';
import { getUserCookie, storeUserCookie } from '../../utils/tools';

const Trash = () => {
    const profile = useSelector(state=>state.profile.data)

    const dispatch = useDispatch();
    const items = useSelector(state=>state.specialFolders.trash).content;
    const isLoading = useSelector(state=>state.specialFolders.loading);
 
    const allAccessibleFolders =  useSelector(state=>state.folders).all;
    const isLoadingAllFolders = useSelector(state=>state.folders.loadingAll);
    const [modalState, setModalState] = useState("close")
    const [destinationFolder, setDestinationFolder] = useState(null);

    const [paginationModel, setPaginationModel] = useState(getUserCookie('grid-pagination') ? getUserCookie('grid-pagination') : {
        pageSize: 50,
        page: 0,
    });

    const [allAccessibleFoldersFiltered, setAllAccessibleFoldersFiltered] = useState([])

    useEffect(()=>{
        setAllAccessibleFoldersFiltered(allAccessibleFolders)
    },[allAccessibleFolders])



    const inputFolderFilter = useRef(null)
  
    const folderFilter = () => {
      const keyword = inputFolderFilter.current.value.toLowerCase()
        setAllAccessibleFoldersFiltered(allAccessibleFolders.filter(function (el) {
  
              return el.title.toLowerCase().includes(keyword);
          }))
    }

    const confirmRestoreSelectedFolder = (destination) => {
        setDestinationFolder(destination)
        //console.log(destination)
        setModalState("modal-confirm-move")
     
      }

      const moveSelectedElement = (destinationFolderId, restore = false, index = 0) => {
      
        if(index == 0) {
            handleClose()
        }
        
        if(index >= selectedRows.length) {
            reloadData()
            setSelectedRows([])
        }
        else {
            if(selectedRows[index].type == 'folder') {
              dispatch(moveFolder({id: selectedRows[index].realId, folderId: destinationFolderId, restore: restore})).then(() => {
                  moveSelectedElement(destinationFolderId, restore, index + 1)
              })
            }
            else {
              dispatch(movePost({id: selectedRows[index].realId, folderId: destinationFolderId, restore: restore})).then(() => {
                  moveSelectedElement(destinationFolderId, restore, index + 1)
              })
            }


        }

       
        
       
    }
  
      // const restoreSelectedItem = () => {
      //   if(currentElement) {
      //     if(currentElement.type == 'folder') {
      //       dispatch(moveFolder({id: currentElement.id, folderId: destinationFolder.id, restore:true}))
      //     }
      //     else {
      //       dispatch(movePost({id: currentElement.id, folderId: destinationFolder.id, restore: true}))
      //     }

      //     setCurrentElement(null)
      //   }
      //   else {
      //     for(let i=0; i < selectedRows.length; i++) {
      //       if(selectedRows[i].type == 'folder') {
      //         dispatch(moveFolder({id: selectedRows[i].id, folderId: destinationFolder.id, restore:true}))
      //       }
      //       else {
      //         dispatch(movePost({id: selectedRows[i].id, folderId: destinationFolder.id, restore: true}))
      //       }
      //     }
      //     setSelectedRows([])

      //   }
      //   dispatch(getTrash())

      //   setDestinationFolder(null)
      //   handleClose()
                   
         
      //   }
     
  
  
    const columns = [
      {
          field: 'type',
          headerName: 'Tipologia',
          filterable: false,
          sortable: false,
          width: 80,
          renderCell: (param) => {
              switch(param.value) {
                  case 'post':
                      return <Tooltip title={"Messaggio"}><PostIcon sx={{ fontSize: 20 }} /></Tooltip>
                  case 'link':
                      return <Tooltip title={"Link"}><LinkIcon  sx={{ fontSize: 20 }} /></Tooltip>
                  case 'attachment':
                      return <Tooltip title={"Allegato"}><AttachmentIcon sx={{ fontSize: 20 }} /></Tooltip>
                  case 'folder':
                      return <Tooltip title={"Cartella"}><FolderIcon sx={{ fontSize: 20 }} /></Tooltip>
              }
          }
      },
      { 
          field: 'title',
          headerName: 'Nome',
          minWidth: 400,
          flex:10,
          renderCell: (param) => {
              const row = param.row
              switch(row.type) {
                  case 'post':
                      return <Tooltip title={row.title}><span>{row.title}</span></Tooltip>
                  case 'attachment':
                      return <Tooltip title={row.content}><span>{row.title}</span></Tooltip>
                  case 'link':
                      return <Tooltip title={row.link}><span>{row.title}</span></Tooltip>
                  
              }
          }
      },
      { 
          field: 'original_path',
          headerName: 'Path',
          minWidth: 200,
          flex:5,
          renderCell: (param) => {
            return <Tooltip title={param.value}><span>{param.value}</span></Tooltip>
          }
      },
      { 
        field: 'delete_date', headerName: 'Data eliminazione', width: 200,
        renderCell: (param) => {
          const formattedDate = formatDate(param.row.delete_date)
          return <Tooltip title={formattedDate}><span>{formattedDate}</span></Tooltip>
        }
      },       
      
      {
          field: 'operations',
          headerName: 'Operazioni',
          filterable: false,
          sortable: false,
          width:150,
          renderCell: (param) => {
              const elem = param.row
              return (
                  <Tooltip title={"Ripristina"}>
                      <IconButton aria-label="move" onClick={(e) => handleShowModalRestore(e, elem)} >
                          <RestoreFromTrashIcon />
                      </IconButton>
                  </Tooltip>
                  
              )
              
              
          }
          
      }
    ];

    const [sortModel, setSortModel] = useState(getUserCookie('grid-sort') &&
    getUserCookie('grid-sort').length > 0 &&
    columns.some(x => x.field == getUserCookie('grid-sort')[0]["field"]) ? getUserCookie('grid-sort') : [{
        field: 'delete_date',
        sort: 'desc'
    }]);

    const [filteredItems, setFilteredItems] = useState([])
    const inputFilter = useRef(null)

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {

            return el.title.toLowerCase().includes(keyword);
        }))
    }

    
    useEffect(()=>{
      //console.log(items)
        setFilteredItems(items)
    },[items])
  
  
    //const [currentElement, setCurrentElement] = useState(null);

    const [selectedRows, setSelectedRows] = useState([]);

    const handleClose = () => {
        //setCurrentElement(null)
        setModalState("close")
    }



    const handleShowModalRestore = (e, currentElement) => {
      if(currentElement) { // from single row
          e.stopPropagation()
          setSelectedRows([currentElement]) // must contain entire object
          setAllAccessibleFoldersFiltered(allAccessibleFolders)

          setModalState("modal-restore")
      }
      else if(selectedRows.length > 0) {
          setAllAccessibleFoldersFiltered(allAccessibleFolders)
          setModalState("modal-restore")
      }
    }

    useEffect(()=>{
      reloadData()

    }, [])

    const reloadData = () => {
      dispatch(getTrash())
      dispatch(getAllAccessibleFolders('write_adv'))
    }

   

    return(
        <>
            

            <Container>
                <Row>
                    <Col md={12}>
                       
                        <LayoutCard title={'CESTINO'} variant="white">
                            {
                                isLoading ?
                                <Loader />
                                :
                                <>
                                  <Col className='background-grey'>
                                      <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                                  </Col>
                                  <>
                                      <IconButton aria-label="move" onClick={handleShowModalRestore} >
                                          <RestoreFromTrashIcon />
                                      </IconButton>

                                  </>
                                  <div style={{ height: 400, width: '100%' }}>
                                      <DataGridPro
                                        sortModel= {sortModel}
                                        onSortModelChange={(model) => {
                                            //console.log(model)
                                            setSortModel(model)
                                            storeUserCookie('grid-sort',model)
                                        }}
                                        paginationModel={paginationModel}
                                        onPaginationModelChange={(param) => {
                                            setPaginationModel(param)
                                            storeUserCookie('grid-pagination', param)
                    
                                        }}
                                        disableRowSelectionOnClick
                                        pagination={true}
                                        pageSizeOptions={[50,100,200,300]}
                                        rowsPerPageOptions={[50, 100, 200,300]}

                                        
                                        checkboxSelection={true}
                                        localeText={itIT.components.MuiDataGrid.defaultProps.localeText}
                                        rows={filteredItems ?? []}
                                        columns={columns}
                                        onRowSelectionModelChange={(ids) => {
                                          const selectedIDs = new Set(ids);
                                          const selectedRowData = filteredItems.filter((row) =>
                                            selectedIDs.has(row.id)
                                          );
                                          setSelectedRows(selectedRowData);//must contain entire objet
                                        }}
                                      />
                                  </div>
                                </>
                            }
                        </LayoutCard>
                    </Col>
                    
                </Row>
              
                
            </Container>
            <Modal show={modalState === "modal-confirm-move"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Sei sicuro?</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              Vuoi davvero ripristinare {selectedRows.length == 1 ? "l'elemento" : "gli elementi"} nella destinazione <br />
              <b>{destinationFolder ? destinationFolder.path + destinationFolder.name : ""}</b>?
            </Modal.Body>
            <Modal.Footer>
              <Button className='ui-button' onClick={() => moveSelectedElement(destinationFolder.id,true)}>Ripristina</Button>
            </Modal.Footer>
          </Modal>
          <Modal show={modalState === "modal-restore"} onHide={handleClose}>
            <Modal.Header closeButton>
              <Modal.Title><span className="green-rectangle" >&nbsp;</span>Ripristina</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              
              <div className='modal-move-body'>
              <Container>
                <Row>
                    <Col>
                        <input onChange={folderFilter} ref={inputFolderFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                </Row>
                {
                  isLoadingAllFolders ?
                  <Loader />
                  :
                  <Row>
                    <Col sm={4} xs={6}>
                      <ElementCard
                        item={{
                          id: 0,
                          title: "Cartella originale",
                          type: "folder"
                        }}
                        handleClick={() => { confirmRestoreSelectedFolder({
                          id: 0,
                          path: "",
                          name: "originale"
                        })}}
                      />
                    </Col>
                    {/* <Col sm={4} xs={6}>
                      <ElementCard
                        item={{
                          id: profile.rootFolder,
                          title: "Home",
                          type: "folder"
                        }}
                        handleClick={() => { confirmRestoreSelectedFolder({
                          id: profile.rootFolder,
                          path: "",
                          name: "Home"
                        })}}
                      />
                    </Col> */}
                    {
                    allAccessibleFoldersFiltered.map((folder,index)=> {
                      const item= {
                        type: 'folder',
                        title: folder.name,
                        mine: folder.owner_id === profile.id,
                        popupTitle: folder.path + folder.name

                      }
                      return (
                        <Col sm={4} xs={6} key={"folder_" + index}>
                          <ElementCard
                            item={item}
                            handleClick={() => { confirmRestoreSelectedFolder(folder)}}
                          />
                        </Col>
                    )
                    })}
                  </Row>
                }
              </Container>
                
              </div>             
            </Modal.Body>
          </Modal>
        </>
    )
}

export default Trash;