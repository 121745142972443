import { Row, Col } from "react-bootstrap"
import { useRef, useState } from "react"
import AddIcon from '@mui/icons-material/Add'
import UserAutocompleter from "./autocompleter/userAutocompleter"
import GroupAutocompleter from "./autocompleter/groupAutocompleter"
import i18n from '../../i18n/translations';
import { useTranslation } from "react-i18next"
import { useEffect } from "react"
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import SaveIcon from '@mui/icons-material/Save';
import CancelIcon from '@mui/icons-material/Cancel';
import { Modal, Button } from "react-bootstrap"
const RightSelector = ({type, element = null, onAdd, onUpdate, onRemove, disabled = false }) => {


    const byteString = (n) => {
        return ("000000000000000" + parseInt(n).toString(2)).split("").reverse()
    }




    const rights = [
        'read',
        'view_history',
        'write_comment',
        'write',
        'write_adv',
        'moderation',
        'permission_management'
    ]

    const rightTypes = [
        {
            name: 'reader',
            right: 3
        },
        {
            name: 'commentator',
            right: 7
        },
        {
            name: 'writer',
            right: 15
        },
        {
            name: 'adv_writer',
            right: 31
        },
        {
            name: 'moderator',
            right: 63
        },
        {
            name: 'handler',
            right: 127
        },
        {
            name: 'disabled',
            right: 16256
        },
        {
            name: 'custom',
            right: 0
        }
    ]

    const originalRight = element ? element.right : 3 //reader


    const [currentRight, setCurrentRight] = useState(originalRight)



    const cancelEdit = () => {
        
        setCurrentRight(originalRight)
        setEditMode(false)
        setShowCheckbox(false)
        setSelectorFromRight()

        
    }

    const calculateRight = () => {
        let right = 0
        for(let i=0;i<rights.length;i++) {
            const elements =  document.querySelectorAll('[class*=' + elementClassName + i)
            if(elements[0].checked) {
                right += 2 ** i
            }

            if(elements[1].checked) {
                right += 2 ** (i + 7)
            }

           
        }
        setCurrentRight(right)
        
    }

    const elementClassName = type + (element ? "-right-" + element.id + "-" : "-new-right-")
    const [target, setTarget] = useState(0)
    const rightTypeSelector = useRef(null)
    const [editMode, setEditMode] = useState(!element)
    const [showCheckbox, setShowCheckbox] = useState(false)

    
    const { t } = useTranslation()
    const [modalState, setModalState] = useState("close")

   
    const hideModal = () => {
        setModalState("close")
    }

    const showModalRemove = () => {
        setModalState("modal-delete")
    }

    const selectCheckboxes = (rightValue) => {
        const base2 = byteString(rightValue)
        rights.map((elem,index) => {
            const classname = elementClassName + index            
            const elements = document.getElementsByClassName(classname) 
            if(elements.length == 2) {
                elements[0].checked = base2[index] == 1
                elements[1].checked = base2[index + 7] == 1
            }             
        })
    }

    const changeRightType = () => {
        setShowCheckbox(true)
        const rightValue = rightTypeSelector.current.value
        if(rightValue > 0) {
            setCurrentRight(rightValue)

            selectCheckboxes(rightValue)
            


        }
    }

    const checkBoxChange = (e) => {
        const classname = e.target.className
        
        const elements = document.getElementsByClassName(classname)
        for(let i=0;i<elements.length;i++) {
            if(e.target.checked && elements[i] !== e.target) {
                elements[i].checked = !e.target.checked
            }
        }
        var option = rightTypeSelector.current.options[7]
        option.selected = true;
        calculateRight()

    }

    const setSelectorFromRight = () => {
        let index = 7
        for(let i=0;i<rightTypes.length;i++) {
            if(currentRight == rightTypes[i].right) {
                index = i
                break
            }
        }
        var option = rightTypeSelector.current.options[index]
        option.selected = true;
        
    }

    useEffect(()=>{
        setSelectorFromRight()
    },[])


 


   
    
    return(
        <>
        <Row>
            <Col xs={4}>
            {
                !element ? 
                (
                    type == "pub" ?
                    ""
                    :
                    (type == "usr" ? 
                    <UserAutocompleter setSelected={setTarget} />
                    :
                    <GroupAutocompleter setSelected={setTarget} />)
                    
                )
                :
                (
                    type == "usr" ? 
                    <label>{element.fullname}</label>
                    :
                    <label>{element.name}</label>
                    
                )
                
            }
           
            </Col>
            <Col xs={4}>
                <select ref={rightTypeSelector} onChange={changeRightType} disabled={!editMode || disabled}>
                    {
                        rightTypes.map((item,index)=>(
                            <option key={index} value={item["right"]}>{t(item["name"])}</option>
                        ))
                    }
                </select>
            </Col>
            
            <Col xs={2}>
                {
                    disabled ?
                    ""
                    :
                    (
                        !editMode ? 
                        <>
                            <EditIcon className='ui-icon' onClick={() => {
                                selectCheckboxes(currentRight)
                                setEditMode(true)
                                
                                }} />
                            <DeleteIcon className='ui-icon' onClick={() => showModalRemove()} />
                        </>
                        :
                        <>
                            {
                                element ?
                                <>
                                    <SaveIcon className='ui-icon' onClick={() => onUpdate({
                                        right_id: element.id,
                                        right: currentRight,
    
                                    })} />
                                    <CancelIcon className='ui-icon' onClick={() => cancelEdit()} />
                                </>
                                :
                                <AddIcon className='ui-icon' onClick={() => {
                                    let data = {
                                        type: type,
                                        right: currentRight,
                                    }
                                    if(type != "pub") {
                                        data.element_id = target
                                    }
    
                                    onAdd(data)
                                }} />
    
    
                            }
                        </>
                    )
                    
                }
                
            </Col>
        </Row>
        <Row>
            <Col xs={12}>
                {
                    <div className={(editMode && element) || showCheckbox ? "" : "hidden" }>
                        <table>
                            <thead>
                                <tr>
                                    <th>Autorizzazioni</th>
                                    <th>Consenti</th>
                                    <th>Nega</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    rights.map((element,index) => {
                                    return (
                                        <tr key={index}>
                                            <td>{t(element)}</td>
                                            <td><input className={elementClassName + index} type="checkbox" onChange={checkBoxChange}></input></td>
                                            <td><input className={elementClassName + index} type="checkbox" onChange={checkBoxChange}></input></td>
                                        </tr>
                                    )
                                })}
                            </tbody>
                            
                           
                        </table>
                    </div>
                }
                
            </Col>
        </Row>
        <Modal show={modalState === "modal-delete"} onHide={hideModal}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Sei sicuro?</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          Vuoi davvero eliminare il permesso selezionato ?
        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={() => {
            hideModal()
            onRemove({
                right_id: element.id
            })

        }}>Elimina</Button>
        </Modal.Footer>
      </Modal>
        </>
    )

}


export default  RightSelector