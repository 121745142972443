import FacebookRounded from '@mui/icons-material/FacebookRounded'
import YouTube from '@mui/icons-material/YouTube'
import Instagram from '@mui/icons-material/Instagram'
import Twitter from '@mui/icons-material/Twitter'
import SimpleSearchIcon from '@mui/icons-material/Search';

import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { FACEBOOK_URL,  INSTAGRAM_URL, ALERT_FOLDER, TWITTER_URL, YOUTUBE_URL } from '../../../utils/costants'
import { useSelector } from 'react-redux'
import { useEffect } from 'react'
import { getMenu } from '../../../store/actions/menuItems'
import { useDispatch } from 'react-redux'
import { IconButton } from '@mui/material';
import { Nav, NavDropdown, Navbar } from 'react-bootstrap';
import { useNavigate } from 'react-router-dom';
import ProfileDropdown from './profileDropdown';
const HeaderTop = () => {

    var options = {'weekday': 'long', 'month': 'long', 'day': '2-digit', 'year': 'numeric'};
    var date = new Date().toLocaleString('it-IT', options);
    date = date.charAt(0).toUpperCase() + date.slice(1);
    const { fullname, admin } = useSelector(state=>state.profile.data)
    const navigate=useNavigate()


    const submitSearch = (e) => {
        if(e.keyCode == 13 && e.shiftKey == false) {

            e.preventDefault()
            navigate("/search?q=" + e.target.value)
            e.target.value = ""
        }
    }

    return(
      <>
        <Navbar className='background-grey top-navbar d-none d-lg-block'>
          <Container>
            <Navbar.Brand>
            <h6>{date}</h6>
            </Navbar.Brand>
            
            <Navbar.Toggle aria-controls="responsive-navbar-nav" />
            <Navbar.Collapse id="responsive-navbar-nav">
              <Nav className="ms-auto">
                <Link to="/search" className='top-search-icon'>
                    <SimpleSearchIcon />
                </Link>
                <input onKeyDown={submitSearch} type="text" placeholder="Cerca" className='' />
                <ProfileDropdown />
              
              </Nav>
              

              
            </Navbar.Collapse>
            
          </Container>
        </Navbar>
       
      </>
    )

}


export default HeaderTop