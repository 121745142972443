

import { Modal, Container, Row, Col, Button } from 'react-bootstrap';

import UserCard from '../elements/userCard'
import FoldersGrid from '../elements/grid/foldersGrid';
import { useDispatch, useSelector } from 'react-redux';
import {getFolderContent} from '../../store/actions/folders'

import { useEffect } from 'react'
import LayoutCard from '../../hoc/layoutCard';
import { useState } from 'react';
import { addFolder } from '../../store/actions/folders'; 
import { Loader } from '../../utils/tools';
import { globalNotification } from '../../store/reducers/notifications';

const UserProfile = () => {
  const folders = useSelector(state=>state.folders);
  const profile = useSelector(state=>state.profile);
  const dispatch = useDispatch();
  const [modalState, setModalState] = useState("close")
  const [subFolderName, setSubfolderName] = useState("")
  const [subFolderDescription, setSubfolderDescription] = useState("")


  const handleShowModalNewSubfolder = () => {
    setModalState("modal-subfolder")
  }

  const handleClose = () => {
    setModalState("close")
  }

  const addSubfolder = () => {
    if(subFolderName == "") {
      dispatch(globalNotification({
        status: "error",
        message: "insert_folder_name"
      }))
      return
    }
    dispatch(addFolder({name: subFolderName, description: subFolderDescription, parentId: profile.data.rootFolder})).then(() => {
      dispatch(getFolderContent({folderId: profile.data.rootFolder}))

    })
    handleClose()
  }

  useEffect(()=>{
      dispatch(getFolderContent({folderId: profile.data.rootFolder}))
      
  },[])
//console.log(folders);
  let content = folders.current ? folders.current.subfolders : null
  let isLoading = !!folders.loading

  return(
    <>
        <Container>
          <Row>
              <Col md={12}>
                <UserCard />
              </Col>
             
          </Row>
          <Row>
              <Col>
              {
                isLoading ?
                <Loader />
                :
                <FoldersGrid
                    items={content}
                    handleShowModalNewSubfolder={handleShowModalNewSubfolder}

                />
              }
                
              </Col>
          </Row>
        </Container>
        <Modal show={modalState === "modal-subfolder"} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title><span className="green-rectangle" >&nbsp;</span>Nuova Cartella</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <input type="text" className='input-subfolder' maxLength="200" placeholder="Nome" onChange={event => setSubfolderName(event.target.value)}  />
          <textarea className='input-editfolder' placeholder="Descrizione" onChange={event => setSubfolderDescription(event.target.value)} ></textarea>

        </Modal.Body>
        <Modal.Footer>
          <Button className='ui-button' onClick={addSubfolder}>Aggiungi</Button>
        </Modal.Footer>
      </Modal>
    </>
  )

}


export default  UserProfile