import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { currentDatetime, getAuthHeader } from '../../utils/tools';
import axios from 'axios'

import {DEFAULT_LIMIT} from '../../utils/costants'


axios.defaults.headers.post['Content-Type'] = 'application/json';


export const getPosts = createAsyncThunk(
    'posts/getPosts',
    async({ dispatch })=>{
        try{
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,getAuthHeader())
            return response.data
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const addPost = createAsyncThunk(
    'posts/addPost',
    async(post,{ dispatch })=>{
        try{
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,post,getAuthHeader());
          

            return response.data;
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)


export const deletePost = createAsyncThunk(
    'posts/deletePost',
    async({id},{ dispatch })=>{
        try{
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`,getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return response.data.success == true;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)
export const deletePosts = createAsyncThunk(
    'posts/deletePosts',
    async({ids},{ dispatch })=>{
        try{
            const data = {
                ids: ids
            }
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,{
               headers: getAuthHeader().headers,
               data: data
            });
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return response.data.success == true;
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)



// export const markPosts = createAsyncThunk(
//     'posts/markPosts',
//     async({ids, markAsRead},{ dispatch })=>{
//         try{
//             const data = {
//                 ids: ids,
//                 mark_as_read: markAsRead
//             }
//             const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,data,getAuthHeader());
//             dispatch(globalNotification(response.data))
//             return data;
//         } catch(error){
//             dispatch(globalNotification(error.response.data))

//         }
//     }
// )



export const editPost = createAsyncThunk(
    'posts/editPost',
    async({id,data},{ dispatch })=>{
        try{

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`,data,getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return data;
        }
        catch(error) {
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

            throw error // i need to catch it in reducer
        }
    }
)

export const editPosts = createAsyncThunk(
    'posts/editPosts',
    async(data,{ dispatch })=>{
        try{
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/posts`,data,getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

// export const movePosts = createAsyncThunk(
//     'posts/movePosts',
//     async(data,{ dispatch })=>{
//         try{
            
//             const request = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/posts/`, data,getAuthHeader());
//             dispatch(globalNotification({
//                 status: "success",
//                 message: data.restore ? "post_restored_successfully" : "post_moved_successfully"
//             }))
          
           
//             return data
//         } catch(error){
//             dispatch(globalNotification({
//                 status: "error",
//                 message: error.response.data.message
//             }))

//         }
//     }
// )

export const movePost = createAsyncThunk(
    'posts/movePost',
    async({id,folderId, restore=false},{ dispatch })=>{
        try{
            const data = {
                folder_id: folderId,
                restore: restore
            }

            await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`, data, getAuthHeader());
            dispatch(globalNotification({
                status: "success",
                message: restore ? "post_restored_successfully" : "post_moved_successfully"
            }))
           
           
            return data
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)




export const addAttachment = createAsyncThunk(
    'posts/addAttachment',
    async({id, file},{dispatch})=>{
        try{
            const formData = new FormData(); 
     
            // Update the formData object 
            formData.append( 
                "attachment", 
                file, 
                file.name 
            ); 
                //console.log(formData)
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments`,formData,getAuthHeader())
            const ret = {
                attachment: response.data['details']['path']
            }
            return ret
        } catch(error){
            dispatch(globalNotification({
                status: error.response.data.status,
                message: error.response.data.message
            }))

        }
    }
)


export const removeAttachment = createAsyncThunk(
    'posts/removeAttachment',
    async({id, attachmentId},{dispatch})=>{
        try{
            const result = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments/${attachmentId}`,getAuthHeader())
            
            return result.data.success == true;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const getPost = createAsyncThunk(
    'posts/getPost',
    async({id},{ dispatch })=>{
        try{
            const post = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}`,getAuthHeader())
            return post.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const getPostAuthor = createAsyncThunk(
    'posts/getPostAuthor',
    async({id},{ dispatch })=>{
        try{

            const author = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/author`,getAuthHeader())
            return author.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const getPostHistory = createAsyncThunk(
    'posts/getPostHistory',
    async({id},{ dispatch })=>{
        try{
         
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/history`,getAuthHeader())
            return response.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const getPostAttachments = createAsyncThunk(
    'posts/getPostAttachments',
    async({id},{ dispatch })=>{
        try{

            const attachments = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments`,getAuthHeader())
            return attachments.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const addPostComment = createAsyncThunk(
    'posts/addPostComment',
    async({authorId, postId, commentId ="", content = ""},{ dispatch })=>{
        try{
           
            const data = {
                post_id: parseInt(postId),
                content: content
            }

            if(commentId) {
                data["comment_id"] = parseInt(commentId)
            }

            const result = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/comments`, data, getAuthHeader())
            return {
                author: authorId,
                children: [],
                comment_id: commentId ?? null,
                content: content,
                id: result.data.id,
                post_id: postId,
                published: currentDatetime()
            };
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const editPostComment = createAsyncThunk(
    'posts/editPostComment',
    async({commentId ="", content = ""},{ dispatch })=>{
        try{
           
            const data = {
                content: content
            }

            const result = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${commentId}`, data, getAuthHeader())
            return result.data.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const deletePostComment = createAsyncThunk(
    'posts/deletePostComment',
    async({commentId},{ dispatch })=>{
        try{
            const result = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/comments/${commentId}`, getAuthHeader())
            return {
                id: commentId
            };
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const getPostComments = createAsyncThunk(
    'posts/getPostComments',
    async({postId, page = 0},{ dispatch })=>{
        try{
            const pid = parseInt(postId)
           
            const limit = DEFAULT_LIMIT
            const offset = page * limit

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${pid}/comments?offset=${offset}&limit=${limit}`,getAuthHeader())
            return result.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const myPostRights = createAsyncThunk(
    'posts/myPostRights',
    async({id},{ dispatch })=>{
        try{

            const result = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/my-rights`,getAuthHeader())
            return result.data;
        }
        catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

