import FolderIcon from '@mui/icons-material/Folder';
import FolderSharedIcon from '@mui/icons-material/FolderShared';
import FolderSharedByMeIcon from '@mui/icons-material/DriveFileMove';
import LinkIcon from '@mui/icons-material/LinkSharp'
import AttachmentIcon from '@mui/icons-material/AttachFile';
import PostIcon from '@mui/icons-material/Article';
import DeleteIcon from '@mui/icons-material/Delete';
import DeviceUnknown from '@mui/icons-material/DeviceUnknown'
import EditIcon from '@mui/icons-material/Edit';
import { Link } from 'react-router-dom';
import { Container, Row, Col } from 'react-bootstrap';
import { getTokenCookie } from '../../utils/tools';
import MoveIcon from '@mui/icons-material/MoveDown'
import { useDispatch } from 'react-redux/es/exports';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';
import { DriveEta, DriveEtaRounded } from '@mui/icons-material';

const ElementCard = ({item, handleDelete, handleEdit, handleMove, index, variant, handleClick, handleRestore, inTrash, readOnly}) => {
    let icon;
    let href = ""
    const dispatch = useDispatch()
    let clickAction  = () => {
    }
    if(handleClick) {
        clickAction = handleClick
    }
    //const url = item.id && item.postId ? process.env.REACT_APP_BACKEND_URL + '/api/posts/' + item.postId + '/attachments/' + item.id : ""
    const fontSize = variant === 'row' ? 30 : 100
    
    switch(item.type) {
        case 'folder':
            if(item.id && !item.deleted)  {
                href = "/folder/" + item.id
            }
            
            if(item.shared) {
                if(item.mine) {
                    //shared by me
                    icon = <FolderSharedByMeIcon sx={{ fontSize: fontSize }}  />

                }
                else {
                    //shared with me
                    icon = <FolderSharedIcon sx={{ fontSize: fontSize }}  />

                }
            }
            else {
                icon = <FolderIcon sx={{ fontSize: fontSize }}  />
            }
            break;
        case 'post':
            if(!item.deleted) {
                href = "/post/" + (item.id != 0 ? item.id : "new");
            }

            icon = <PostIcon sx={{ fontSize: fontSize }}  />
            break;
        case 'file':
            // if(url) {
            //     clickAction = downloadAttachment.bind(url)
            // }
            // icon = <AttachmentIcon sx={{ fontSize: fontSize }}  />
            break;
        case 'attachment':
            if(!item.deleted) {
                href = "/post/" + item.postId + "/attachment/" + item.id;
            }
            icon = <AttachmentIcon sx={{ fontSize: fontSize }}  />
            break;
        case 'link':
            icon = <LinkIcon sx={{ fontSize: fontSize }}  />
            clickAction= () => {
                window.open(item.link, "_blank");

            }
            break;
        default:
            icon = <DeviceUnknown sx={{ fontSize: fontSize }}  />
        
    }

    const operationsButtons= []

    if(!readOnly) {
        if(!item.deleted) {
            if(handleDelete) {
                operationsButtons.push(<a key="op_delete" onClick={(e) => handleDelete(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: item.type
                })}><DeleteIcon sx={{ fontSize: 20 }} /> </a>)
            }
            if(handleEdit) {
                operationsButtons.push(<a key="op_edit" onClick={(e) => handleEdit(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: item.type
                })}><EditIcon sx={{ fontSize: 20 }} /> </a>)
            }
            if(handleMove) {
                operationsButtons.push(<a key="op_move" onClick={(e) => handleMove(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: item.type,
                    //path: item && item.path ? item.path + "/" + item.name : "",
                })}><MoveIcon sx={{ fontSize: 20 }} /> </a>)
            }
        
            if(handleClick) {
                href = "";
            }
        }
        else {
            if(handleRestore) {
                operationsButtons.push(<a key="op_restore" onClick={(e) => handleRestore(e, {
                    id: item && item.id ? item.id : 0,
                    index: index,
                    type: item.type,
                    //path: item && item.path ? item.path + "/" + item.name : "",
                })}><RestoreFromTrashIcon sx={{ fontSize: 20 }} /> </a>)
            }
        }
    }
    
    
    if(variant == "row") {
        const className = item.deleted && !inTrash ? "folder-row-content deleted-element" : "folder-row-content"

        return(
                <Row className={"folder-row-separator"}>
                    <Col xs={1}>
                        <span className={className}>{icon}</span>
                    </Col>
                    <Col xs={9} className="folder-row-title">
                        <Link to={href} onClick={clickAction} title={item.title}>

                            <span>{item.title}</span>
                        </Link>

                    </Col>
                    <Col xs={2} className="folder-row-operations-col">
                        <div>
                            {operationsButtons}
                        </div>                       
                    </Col>
                </Row>

                  

                
            
            
        )
    }
    else {
        let iconClassName = "element-card-content";
        iconClassName += item.id == 0 ? " grey-content" : " green-content";
        iconClassName += item.deleted && !inTrash ? " deleted-element" : "";
        return(
            <div className={"element-card highlight-element dropdown-container"} title={item.title}>
                {
                href ?
                    <Link to={href} onClick={clickAction} title={item.popupTitle} >
                        <span className={item.id == 0 ? 'grey-rectangle' : "green-rectangle"} >&nbsp;</span><strong>{item.title}</strong>
                        <div className={iconClassName}>
                            {icon}
                        </div>
                        
                    </Link>
                    :
                    <div onClick={clickAction} title={item.popupTitle} >
                        <span className={item.id == 0 ? 'grey-rectangle' : "green-rectangle"} >&nbsp;</span><strong>{item.title}</strong>
                        <div className={iconClassName}>
                            {icon}
                        </div>
                        
                    </div>
            
                }
                
                <div className="dropdown-content">
                    {operationsButtons}                    
                </div>
            </div>
        )
    }
    

}


export default  ElementCard