import { createAsyncThunk } from '@reduxjs/toolkit'
import { globalNotification } from '../reducers/notifications';
import { getAuthHeader } from '../../utils/tools';
import axios from 'axios'
import { useSelector } from 'react';




axios.defaults.headers.post['Content-Type'] = 'application/json';



export const getFolderContent = createAsyncThunk(
    'folders/getFolderContent',
    async({folderId = 0, showDeletedElements = 0},{ dispatch })=>{
        try{
            const includeDeleted = showDeletedElements ? 1 : 0
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/content?includeDeleted=${includeDeleted}`,getAuthHeader())

            return response.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)


export const deleteFolder = createAsyncThunk(
    'folders/deleteFolder',
    async({folderId = 0},{ dispatch })=>{
        try{
         
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}`,getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return response.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const addFolder = createAsyncThunk(
    'folders/addFolder',
    async({parentId, name, description},{ dispatch })=>{
        try{
            const data = {
                parent_id: parentId,
                name: name,
                description: description
            }
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/folders`,data, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return {
                type: 'folder',
                id: response.data.id,
                title: name
            };
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const updateFolder = createAsyncThunk(
    'folders/updateFolder',
    async({folderId, data},{ dispatch })=>{
        try{

            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}`,data, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return data
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))
        }
    }
)

export const myFolderRights = createAsyncThunk(
    'folders/myFolderRights',
    async({id = 0,parentId = 0},{ dispatch })=>{
        try{
            const current = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${id}/my-rights`,getAuthHeader())
            let parent = {
                data: []
            }
            if(parentId > 0) {
                parent = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${parentId}/my-rights`,getAuthHeader())

            }

            return {
                current: current.data,
                parent: parent.data
            }
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
        
    }
)

export const getAllFolderRights = createAsyncThunk(
    'folders/getAllFolderRights',
    async({folderId = 0},{ dispatch })=>{
        try{
            const request = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/rights`,getAuthHeader())
            return request.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
        
    }
)

export const getAllAccessibleFolders = createAsyncThunk(
    'folders/getAllAccessibleFolders',
    async(right,{ dispatch })=>{
        try{
            const request = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/folders/all/${right}`,getAuthHeader())
            return request.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
        
    }
)
export const getHomeFolders = createAsyncThunk(
    'folders/getHomeFolders',
    async(right,{ dispatch })=>{
        try{
            const request = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/api/home/folders`,getAuthHeader())
            return request.data;
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
        
    }
)
export const addFolderRight = createAsyncThunk(
    'folders/addFolderRight',
    async({folderId,data},{ dispatch })=>{
        try{
            const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/rights`,data, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))



        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const updateFolderRight = createAsyncThunk(
    'folders/updateFolderRight',
    async({folderId,rightId,right},{ dispatch })=>{
        try{
            const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/rights/${rightId}`,{
                right: right
            }, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))
            return response.data;


        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)

export const deleteFolderRight = createAsyncThunk(
    'folders/deleteFolderRight',
    async({folderId,rightId},{ dispatch })=>{
        try{
            const response = await axios.delete(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${folderId}/rights/${rightId}`, getAuthHeader())
            dispatch(globalNotification({
                status: "success",
                message: response.data.message
            }))

            return {
                rightId: rightId
            };
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)


export const moveFolder = createAsyncThunk(
    'folders/moveFolder',
    async({id,folderId, restore = false},{ dispatch })=>{
        try{
            let newData = {
                parent_id: folderId
            }

            if(restore) {
                await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${id}/restore`, newData,getAuthHeader());
                dispatch(globalNotification({
                    status: "success",
                    message: "folder_restored_successfully"
                  }))
            }
            else {
                const response = await axios.put(`${process.env.REACT_APP_BACKEND_URL}/api/folders/${id}`, newData,getAuthHeader());
                
                dispatch(globalNotification({
                    status: "success",
                    message: "folder_moved_successfully"
                  }))

                  newData["path"] = response.data.data["path"]
            }
            
            return newData
        } catch(error){
            dispatch(globalNotification({
                status: "error",
                message: error.response.data.message
            }))

        }
    }
)
