import React, {useRef} from 'react'

const FileUploader = (props) => {
    const fileInput = useRef(null)

    const handleFileInput = (e) => {

        // handle validations
        for(let i=0; i < e.target.files.length; i++) {
            const file = e.target.files[i];

            if(file) {
                if (file.size > props.maxSize) {
                    props.onFileSelectError({ error: "Il file supera le dimensioni massime consentite (" + (props.maxSize / (1024 * 1024)).toFixed(2) + "MB)"});
                    return
                }
        
                props.onFileSelectSuccess(file);
            }
        }

        e.target.value = ""
        
    }

    return (
        <div className="file-uploader">
            <input accept={props.accept} ref={fileInput} type="file" onChange={handleFileInput} style={{display: 'none'}} multiple={props.multiple} />
            <div onClick={e => fileInput.current && fileInput.current.click()} className="ui-icon">
                {props.children} <br></br>
                {props.title}
            </div>
        </div>
    )
}


export default FileUploader