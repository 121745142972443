import TitleBar from '../elements/titlebar'
import { Container, Row, Col } from 'react-bootstrap';
import FileUploader from '../utils/fileUploader';
import {Button} from 'react-bootstrap'
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import AccountAvatar from '../elements/accountAvatar';
import { addPost, addAttachment } from '../../store/actions/posts';
import FileUploadIcon from '@mui/icons-material/FileUpload'
import ElementCard from '../elements/elementCard';
import { globalNotification } from '../../store/reducers/notifications';
import HtmlEditor from '../elements/htmlEditor';
import { MAX_ATTACHMENT_SIZE } from '../../utils/costants';
import usePrompt from '../../utils/prompt.ts';
import { getSettings } from '../../store/actions/settings';

//import MultiUploader from '../elements/multiUploader.js';

const NewPost = () => {
    const { id } = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate()
    const [title, setTitle] = useState('')
    const [content, setContent] = useState('')
    const [disablePublish, setDisablePublish] = useState(false)
    const [postCreated, setPostCreated] = useState(false);

    const [selectedFiles, setSelectedFiles] = useState([]);


    const handleEditorState = (state) => {
        setContent(state)
    }

    const settings = useSelector(state=>state.settings.settings);
    const [maxAttachmentSize, setMaxAttachmentSize] = useState(0)

    useEffect(()=>{
        
        dispatch(getSettings())
    
    }, [])
    useEffect(()=>{
        let max_attachment_size = 0
        for(let i = 0; i < settings.length; i++) {
            if(settings[i]['name'] == 'max_attachment_size') {
                max_attachment_size = settings[i]['value']
            }
        }
        if(!max_attachment_size) {
            max_attachment_size = MAX_ATTACHMENT_SIZE
        }
        setMaxAttachmentSize(max_attachment_size)

    }, [settings])


    usePrompt(
        "ATTENZIONE! Le modifiche al messaggio non saranno salvate! Sei sicuro di voler uscire?",
        !postCreated
    );




    const { fullname, appendSignature, signature } = useSelector(state=>state.profile.data)
    //console.log(appendSignature, signature)
    const addSelectedFile = (file) => {
        selectedFiles.push({
            title: file.name,
            type: "attachment",
            file: file,
            postId: id
        })
        setSelectedFiles([...selectedFiles])//trick to re-render component
    }

    const removeSelectedFile = (event, element) => {
        //console.log("element",element)
        selectedFiles.splice(element.index,1)
        setSelectedFiles([...selectedFiles])//trick to re-render component
    }

    const closePage = () => {
        navigate("/folder/" + id,{ replace: true })

    }

    const publishPost = () => {
        if(title.length == 0) {
            dispatch(globalNotification({
                status: "error",
                message: "insert_title_and_content"
            }))
            return
        }
        setDisablePublish(true)
        
        dispatch(addPost({title: title, content: content, folder_id: id})).then( (data) => {
            if(selectedFiles && selectedFiles.length > 0 ) {
                dispatch(globalNotification({
                    status: "success",
                    message: "wait_for_load"
                }))
                for(let i=0; i < selectedFiles.length; i++) {
                    dispatch(addAttachment({id: data.payload.id, file: selectedFiles[i]["file"]}))
                }
            }
            else {
                dispatch(globalNotification({
                    status: "success",
                    message: "create_post_success"
                }))
            }
            setPostCreated(true)


            
        })
    }

    useEffect(() => {
        if(postCreated) {
            navigate("/folder/" + id,{ replace: true })
        }
        setContent(appendSignature ? signature : "")


    },[postCreated])
    

    return(
        <>
            <Container>
                <Row>
                    <Col md={8}>
                        <div className="grey-card left-content" >
                            <div>
                                <AccountAvatar /> {fullname}
                            </div>
                            <div className='post-title-container'>
                                <span className="green-rectangle-input" >&nbsp;</span>

                                <input type="text" maxLength="255" className='post-title' placeholder="Titolo" onChange={event => setTitle(event.target.value)}  />
                            </div>
                            
                        </div>
                    </Col>
                    <Col md={4}>
                        <div className="d-grid gap-2">
                            <Button disabled={disablePublish} size="lg" className="ui-button" onClick={publishPost}>PUBBLICA</Button>
                        </div>
                        <div className="d-grid gap-2">
                            <Button size="lg" className="ui-button grey" onClick={closePage}>CHIUDI</Button>
                        </div>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <HtmlEditor
                            setEditorState={(state)=>handleEditorState(state)}
                            initialValue={appendSignature ? signature : ""}
                        />
                        
                        </Col>
                </Row>
            </Container>
            <TitleBar title={"ALLEGATI"} color={"#EDEDED"} />
            <Container>
                <Row>
                    { selectedFiles.map((item,index)=>(
                        <Col md={3} sm={6} key={"col_" + index}>
                            <ElementCard index={index} item={item} type="file" handleDelete={removeSelectedFile} handleClick={() => {}} />
                        </Col>
                       
                    ))}
                    <Col md={3} sm={6}>
                        {/* <MultiUploader
                            onUpload={(file) => addSelectedFile(file)}
                        >
                            
                        </MultiUploader> */}
                        <FileUploader
                            title={"Carica file"}
                            accept={"*/*"}
                            onFileSelectSuccess={(file) => addSelectedFile(file)}
                            onFileSelectError={({ error }) => { dispatch(globalNotification({
                                status: "error",
                                message: error
                            })) }}
                            multiple={true}
                            maxSize={maxAttachmentSize}
                            >
                            <FileUploadIcon sx={{ fontSize: 100 }} />
                        </FileUploader>
                    </Col>
                </Row>
            
            </Container>
            
            
        </>
        
    )

}


export default  NewPost