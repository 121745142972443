import CircularProgress from '@mui/material/CircularProgress';
import { toast } from 'react-toastify';
import Cookies from 'universal-cookie';
import { globalNotification } from '../store/reducers/notifications';


export const errorHelper = (formik,values) => ({
    error: formik.errors[values] && formik.touched[values] ? true :false,
    helperText: formik.errors[values] && formik.touched[values] ? formik.errors[values]:null
})

export const Loader = () => (
    <div className='root_loader'>
        <CircularProgress style={{'color': '#006643'}} />
    </div>
)

export const showToast = (type,msg) => {
    
    switch(type){
        case 'success':
            toast.success(msg,{
                position: toast.POSITION.BOTTOM_RIGHT
            })
        break;
        case 'warning':
            toast.warning(msg,{
                position: toast.POSITION.BOTTOM_RIGHT
            })
        break;
        case 'error':
            toast.error(msg,{
                position: toast.POSITION.BOTTOM_RIGHT
            })
        break;
        default:
            return false

    }
}

export const storeTokenCookie = (token) => {
    // const cookies = new Cookies()
    // cookies.set('access-token', token, {
    //     path: '/',
    //     secure: true,
    //     maxAge: 60 * 60 * 24 * 7,

    // })
    storeUserCookie('access-token', token, {
        path: '/',
        secure: true,
        maxAge: 60 * 60 * 24 * 7,

    })

}
export const getTokenCookie = () => {
    // const cookies = new Cookies();
    // return cookies.get('access-token');
    //cookie.load('access-token');
    return getUserCookie('access-token')
}
export const removeTokenCookie = () => {
    // const cookies = new Cookies();
    // cookies.remove('access-token', { path: '/' });
    //cookie.remove('access-token',{path:'/'});
    removeUserCookie('access-token')
}
export const getAuthHeader = () => {
    return { headers:{'Auth-Token': getTokenCookie()}}
}

export const storeUserCookie = (name, value, options) => {
    if(!options) {
        options = {
            path: '/',
            secure: true,
            maxAge: 60 * 60 * 24 * 10000,
    
        }
    }
    const cookies = new Cookies()
    cookies.set(name, value, options)

}

export const getUserCookie = (name) => {
    const cookies = new Cookies();
    return cookies.get(name);

}

export const removeUserCookie = (name) => {
    const cookies = new Cookies();
    cookies.remove(name, { path: '/' });
}




export const htmlDecode = (input) => {
    const doc = new DOMParser().parseFromString(input,"text/html")
    return doc.documentElement.textContent;
}

// export const toLocaleDatetimeString = (datetime) => {
//     const date = new Date(datetime)
//     if(date.getTime() > 0) {
//         return date.toLocaleDateString() + " " + date.toLocaleTimeString()
//     }
//     else {
//         return ""
//     }

// }




export const removeTags = (str) => {
    if ((str===null) || (str===''))
        return '';
    else
        str = str.toString();
          

    //return str.replace( /(<([^>]+)>)/ig, '');
    return str.replace(/<[^>]*(>|$)|&nbsp;|&zwnj;|&raquo;|&laquo;|&gt;/g, '');
}

export const currentDatetime = () => {
    var d = new Date()
    return  [
                d.getFullYear(),
                d.getMonth()+1,
                d.getDate()
            ].join('-')+' '+
              [d.getHours(),
               d.getMinutes(),
               d.getSeconds()].join(':');
}

function padTo2Digits(num) {
    return num.toString().padStart(2, '0');
}

// export const formatDate = (date) => {
//     return (
//         [
//         date.getFullYear(),
//         padTo2Digits(date.getMonth() + 1),
//         padTo2Digits(date.getDate()),
//         ].join('-') +
//         ' ' +
//         [
//         padTo2Digits(date.getHours()),
//         padTo2Digits(date.getMinutes()),
//         padTo2Digits(date.getSeconds()),
//         ].join(':')
//     );
// }


export const formatDate = (val = null) => {
    let date;
    if(val) {
        date = new Date(val)
        return (
            [
                padTo2Digits(date.getDate()),
                padTo2Digits(date.getMonth() + 1),
                date.getFullYear()
            ].join('/') +
            ' - ' +
            [
                padTo2Digits(date.getHours()),
                padTo2Digits(date.getMinutes())
            ].join(':')
        );
    }
    else {
        return ""
        //date = new Date()
    }
    
}

export const formatDateEmail = (val = null) => {
    let date;
    if(val) {
        date = new Date(val)
        return date.toLocaleString('default', { month: 'short', day: '2-digit', hour: '2-digit', minute: '2-digit' });

    }
    else {
        return ""
        //date = new Date()
    }

}


export const truncate = (source, size) => {
    return source.length > size ? source.slice(0, size - 1) + "…" : source;
}

export const downloadAttachment = (url) => {
    return new Promise((resolve, reject) => {
        let fileName = ""
        fetch(url, {
            method: 'GET',
            headers: {'Auth-Token': getTokenCookie()}
        })
        .then((response) => response.json())
        .then((json) => {
            fileName = json.filename
            if(!fileName) {
                //TODO show error
                globalNotification({
                    status: "error",
                    message: "invalid_attachment_download"
                })
                return
            }
            fetch(url + "/download", {
                method: 'GET',
                headers: {'Auth-Token': getTokenCookie()}
            })
            .then((response) => response.blob())
            .then((blob) => {
                // Create blob link to download
                const url = window.URL.createObjectURL(
                new Blob([blob]),
                );
                const link = document.createElement('a');
                link.href = url;
                link.setAttribute(
                'download',
                fileName,
                );
        
                // Append to html link element page
                document.body.appendChild(link);

                //on MacOS works only with settimeout
                setTimeout(function() { 
                    // Start download
                    link.click();
            
                    // Clean up and remove the link
                    link.parentNode.removeChild(link);
                    //resolve after 3 second for MacOS
                    setTimeout(function() { 
                        resolve(1)
                    }, 3000); 
                }, 1000); 
                
            }); 
        })
    });
    
}



export const isProduction = () => {
    return process.env.NODE_ENV === "production"
}