import { createSlice } from '@reduxjs/toolkit';
import {
    getFolderContent, getHomeFolders, deleteFolder, updateFolder, moveFolder, addFolder, getAllFolderRights, addFolderRight, deleteFolderRight, getAllAccessibleFolders, myFolderRights, updateFolderRight
} from '../actions/folders';

let DEFAULT_CURRENT_FOLDER = {
    content: [],
    allRights: {
        usr: [],
        grp: [],
        pub: []
    },
    rights: [],
    parentRights: [],
    loadingRights: false

    
}
let DEFAULT_STATE = {
    loading:false,
    current: DEFAULT_CURRENT_FOLDER,
    all: [],
    loadingAll: false,
    loadingHomeFolders: false,
    homeFolders: []
    
}

export const foldersSlice = createSlice({
    name:'folders',
    initialState: DEFAULT_STATE,
    reducers:{
    },
    extraReducers:(builder)=>{
        builder

        .addCase(getFolderContent.pending,(state)=>{ state.loading = true })
        .addCase(getFolderContent.fulfilled,(state,action)=>{
            state.loading = false;
            state.current = {...state.current,...action.payload}
        })
        .addCase(getFolderContent.rejected,(state)=>{ state.loading = false })



        .addCase(updateFolder.pending,(state)=>{ state.loading = true })
        .addCase(updateFolder.fulfilled,(state,action)=>{
            state.loading = false;
            
            state.current = {...state.current,...action.payload}
        })
        .addCase(updateFolder.rejected,(state)=>{ state.loading = false })

        .addCase(addFolder.pending,(state)=>{ state.loading = true })
        .addCase(addFolder.fulfilled,(state,action)=>{
            state.loading = false;
            state.current.content.unshift(action.payload)
        })
        .addCase(addFolder.rejected,(state)=>{ state.loading = false })

        .addCase(deleteFolder.pending,(state)=>{ state.loading = true })
        .addCase(deleteFolder.fulfilled,(state,action)=>{
            state.loading = false;
            //state.current = DEFAULT_CURRENT_FOLDER
        })
        .addCase(deleteFolder.rejected,(state)=>{ state.loading = false })

        .addCase(getAllFolderRights.pending,(state)=>{ state.current.loadingRights = true })
        .addCase(getAllFolderRights.fulfilled,(state,action)=>{
            state.current.loadingRights = false;
            state.current.allRights = action.payload
        })
        .addCase(getAllFolderRights.rejected,(state)=>{ state.current.loadingRights = false })

        .addCase(addFolderRight.pending,(state)=>{ state.current.loadingRights = true })
        .addCase(addFolderRight.fulfilled,(state,action)=>{ state.current.loadingRights = false })
        .addCase(addFolderRight.rejected,(state)=>{ state.current.loadingRights = false })

        .addCase(updateFolderRight.pending,(state)=>{ state.current.loadingRights = true })
        .addCase(updateFolderRight.fulfilled,(state,action)=>{ state.current.loadingRights = false })
        .addCase(updateFolderRight.rejected,(state)=>{ state.current.loadingRights = false })

        .addCase(deleteFolderRight.pending,(state)=>{ state.current.loadingRights = true })
        .addCase(deleteFolderRight.fulfilled,(state,action)=>{ state.current.loadingRights = false })
        .addCase(deleteFolderRight.rejected,(state)=>{ state.current.loadingRights = false })

        .addCase(getAllAccessibleFolders.pending,(state)=>{ state.loadingAll = true })
        .addCase(getAllAccessibleFolders.fulfilled,(state,action)=>{
            state.loadingAll = false;
            state.all = action.payload
     
        })
        .addCase(getAllAccessibleFolders.rejected,(state)=>{ state.loadingAll = false })

        .addCase(getHomeFolders.pending,(state)=>{ state.loadingHomeFolders = true })
        .addCase(getHomeFolders.fulfilled,(state,action)=>{
            state.loadingHomeFolders = false;
            state.homeFolders = action.payload
        })
        .addCase(getHomeFolders.rejected,(state)=>{ state.loadingHomeFolders = false })

        .addCase(moveFolder.pending,(state)=>{ state.loading = true })
        .addCase(moveFolder.fulfilled,(state,action)=>{
        
            state.loading = false;
            if(action.payload) {
                state.current["parent_id"] = action.payload.parent_id
                state.current["path"] = action.payload.path

            }

        })
        .addCase(moveFolder.rejected,(state)=>{ state.loading = false })
       
        
        .addCase(myFolderRights.fulfilled,(state,action)=>{
            state.current.rights = action.payload.current
            state.current.parentRights = action.payload.parent

        })
    }
})


export default foldersSlice.reducer;
