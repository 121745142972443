import { Loader } from '../../../utils/tools';
import { Container, Row, Col, Button } from 'react-bootstrap';
import ElementCard from '../elementCard';
import CreateNewFolderIcon from '@mui/icons-material/CreateNewFolder';
import PostAddIcon from '@mui/icons-material/PostAdd';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import SearchIcon from '@mui/icons-material/Search';
import { useEffect, useMemo, useState} from 'react';
import { useNavigate } from 'react-router-dom';
import { DEFAULT_LIMIT } from '../../../utils/costants';
import { useRef } from 'react';
const ElementsGrid = ({
    folderId,
    items,
    loadMore,
    view,
    onlyFolders = false,
    onlyPosts = false,
    handleShowModalNewSubfolder,
    readOnly = false,
    handleDeleteElement,
    handleMoveElement,
    handleEditElement,
    handleClickElement,
    handleRestoreElement,
    inTrash = false
}) => {

    const [filteredItems, setFilteredItems] = useState(items)
    const inputFilter = useRef(null)
    const navigate = useNavigate()
    const [includeDeleted, setIncludeDeleted] = useState(false)
    const createNewPost = () => {
    navigate("/folder/" + folderId + "/new-post")
    }

    
    useEffect(()=>{
        setFilteredItems(items)
    },[items])
  

    const gridFilter = () => {
        const keyword = inputFilter.current.value.toLowerCase()
        setFilteredItems(items.filter(function (el) {

            return el.title.toLowerCase().includes(keyword);
        }))
    }
    return(
        <>
            
            <Container>
                <Row>
                    <Col className='background-grey'>
                        <input onChange={gridFilter} ref={inputFilter} type="text" placeholder="Filtra..." className='filter-input' />
                    </Col>
                    
                    
                    {readOnly || inTrash ? 
                    ""
                    :
                    <>
                    {
                        onlyFolders ?
                        "" 
                        :
                        <Col xs={2} lg={1} className='elements-grid-bar' onClick={createNewPost}>
                            <PostAddIcon />
                        </Col>
                    }
                    {
                        onlyPosts ?
                        "" 
                        :
                        <Col xs={2} lg={1} className='elements-grid-bar' onClick={handleShowModalNewSubfolder}>
                            <CreateNewFolderIcon />
                        </Col>
                    }
                    
                    </>
                    }
                    
                </Row>
            </Container>
            
            
            <Container className="folder-container">
            { filteredItems && filteredItems.length > 0 ?
                <Row>
                { filteredItems.map((item,index)=>{

                  


                    if((!onlyFolders || item.type === "folder") &&
                    (!onlyPosts || item.type === "post")) {
                        return(
                            <Col md={3} sm={6} lg={2} key={index}>
                                <ElementCard
                                    index={index}
                                    item={item}
                                    handleDelete={handleDeleteElement}
                                    handleEdit={handleEditElement}
                                    handleMove={handleMoveElement}
                                    handleClick={handleClickElement}
                                    handleRestore={handleRestoreElement}
                                    inTrash={inTrash}
                                    readOnly={readOnly}
                                />
                            </Col>
                        )
                        
                       
                        
                    }
                })}
                </Row>
                :
            <div><p>Nessun elemento da visualizzare</p></div>
        }
            {/* <div className='elements-grid-footer'>
                {
                    loadMore && items && items.length > 0 && items.length % DEFAULT_LIMIT === 0 ?
                    <Button onClick={loadMore} className="ui-button">
                        Carica altri
                    </Button>
                    :
                    ""
                }
                
            </div> */}
            
            </Container>
        </>
    )

}

export default ElementsGrid