
import { Tooltip } from '@mui/material';
import { Link } from 'react-router-dom';

const Box = ({
    title,
    tooltip,
    icon,
    href,
    operationsButtons,
    deleted = false,
    folder = false
}) => {


    let iconClassName = "box-content";
    iconClassName += (deleted ? " deleted-element" : "");

    let boxClassName = folder ? "folder-card highlight-element dropdown-container" : "element-card highlight-element dropdown-container"
    return(
        <div className={boxClassName}>
            <Tooltip title={tooltip ?? title}>
                <Link to={href}>
                    <div className={iconClassName}>
                        {icon}
                    </div>
                    <strong className='box-title'>{title}</strong>
                </Link>
            </Tooltip>
            
            <div className="dropdown-content">
                {operationsButtons}                    
            </div>
        </div>
    )
}



export default Box