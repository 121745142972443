import { useNavigate, useParams } from "react-router-dom";
import { Loader, downloadAttachment, getTokenCookie } from "../../utils/tools";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { WindowSharp } from "@mui/icons-material";
import { useTranslation } from 'react-i18next';
import i18n from '../../i18n/translations';

const DownloadAttachment = () => {

    const { id, attId } = useParams();
    const { t } = useTranslation();

    const navigate = useNavigate()
    useEffect(() => {
        const url = `${process.env.REACT_APP_BACKEND_URL}/api/posts/${id}/attachments/${attId}`
      
        downloadAttachment(url).then(() => {
            if(window.history.state && window.history.state.idx > 0) {
                navigate(-1)
            }
            else {
                window.close()
            }

        })
        
    },[])
    
   
    return(
        <>
            <Loader />
            <div className="element-card-content">{t("downloading_file")}</div>
        </>
    )
}

export default DownloadAttachment