
const LayoutCard = (props) => {

    return(
        <div className='layout-card'>
            <span className="green-rectangle" >&nbsp;</span><strong className="layout-card-title">{props.title}</strong>
            <br></br>
            <br></br>
            <div className={props.variant == "white" ? "white-card" : "grey-card"}>
                {props.children}
            </div>

      
        </div>
    )

}


export default LayoutCard